import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Box, Tabs, Tab, Typography } from '@mui/material';

function VotesTab({ id }) {
  const [votesData, setVotesData] = useState({});
  const [isVotesDataFetched, setIsVotesDataFetched] = useState(false);
  const [value, setValue] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('candidate_name');

  useEffect(() => {
    if (!isVotesDataFetched) {
      fetch(`${process.env.REACT_APP_API_URL}/bill-votes/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setVotesData(data);
          setIsVotesDataFetched(true);
        })
        .catch(error => console.error('Error fetching votes data:', error));
    }
  }, [id, isVotesDataFetched]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedVotes = (votes) => {
    return [...votes].sort((a, b) => {
      const aValue = a[orderBy] ?? '';
      const bValue = b[orderBy] ?? '';
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
  };

  const keys = Object.keys(votesData);

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange} aria-label="votes tabs">
        {keys.map((key, index) => (
          <Tab label={key} key={index} />
        ))}
      </Tabs>
      {keys.map((key, index) => (
        <TabPanel value={value} index={index} key={index}>
          {votesData[key].map((rollCall, rollCallIndex) => (
            <React.Fragment key={rollCallIndex}>
              <h3>Roll Call: {rollCall.name}</h3>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, border: '1px solid #E0E0E0' }} aria-label="votes table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#E0E0E0' }}>
                      <TableCell sx={{ border: '1px solid #E0E0E0' }}>
                        <TableSortLabel
                          active={orderBy === 'sponsor'}
                          direction={orderBy === 'sponsor' ? order : 'asc'}
                          onClick={() => handleRequestSort('sponsor')}
                        >
                          Sponsor
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ border: '1px solid #E0E0E0' }}>
                        <TableSortLabel
                          active={orderBy === 'candidate_name'}
                          direction={orderBy === 'candidate_name' ? order : 'asc'}
                          onClick={() => handleRequestSort('candidate_name')}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ border: '1px solid #E0E0E0' }}>
                        <TableSortLabel
                          active={orderBy === 'vote'}
                          direction={orderBy === 'vote' ? order : 'asc'}
                          onClick={() => handleRequestSort('vote')}
                        >
                          Vote
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedVotes(rollCall.votes).map((vote, voteIndex) => (
                      <TableRow key={voteIndex}>
                        <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.sponsor}</TableCell>
                        <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.candidate_name}</TableCell>
                        <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.vote}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          ))}
        </TabPanel>
      ))}
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default VotesTab;