import React, { useState,useEffect } from 'react';
import { AppBar, Toolbar, Typography, InputBase, Box, Button, Link } from '@mui/material';
import { alpha, styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import PieChartIcon from '@mui/icons-material/PieChart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link as RouterLink , useLocation} from 'react-router-dom';

const StyledLink = styled(RouterLink)({
  display: 'flex',
  marginBottom: '8px',
  color: '#000000',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline', // Optional: Add underline on hover
  },
});

const Search = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  marginLeft: 0,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50ch',
    },
  },
}));

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const q = params.get('q');
    if (q !== searchQuery) {
      setSearchQuery(q || '');
    }
  }, [location.search]);
  
  const handleLogout = () => {
    // Clear session storage
    sessionStorage.removeItem('loginData');
    // Redirect to the login page
    navigate('/');
  };

  const handleSearch = async () => {
    navigate(`/home?q=${encodeURIComponent(searchQuery)}`);
  };

  const appBarHeight = 120; 

  return (
    <Box>
      <AppBar sx={{ backgroundColor: '#F4F4F4' }}>
        <Toolbar>
          <Link href="/home">
            <img src="/images/logo.svg" alt="Logo" style={{ marginRight: '16px', height: '40px' }} />
          </Link>
        </Toolbar>
        <Box sx={{ color: '#000000', backgroundColor: '#D4D4D4', padding: '8px 0', width: '100vw', marginLeft: 'calc(-50vw + 50%)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Search sx={{ width: '33.33%', backgroundColor: 'white', display: 'flex', alignItems: 'center' }}>
            <SearchIconWrapper sx={{ padding: '0 8px' }}>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
          </Search>
        </Box>
      </AppBar>
      
      <Box sx={{ width: '100vw', height: '100vh', marginLeft: 'calc(-50vw + 50%)', position: 'relative', display: 'flex', marginTop: `${appBarHeight}px` }}>
        <Box sx={{ backgroundColor: '#E8EAEA', width: '200px', height: '100%', padding: '16px', position: 'fixed', top: `${appBarHeight}px`, left: 0 }}>
          <StyledLink to="/home?tracked=1">
            <TurnedInIcon sx={{ marginRight: '8px' }} />
            Tracker
          </StyledLink>
          
          {/* <StyledLink>
            <NotificationsIcon sx={{ marginRight: '8px' }} />
            Alerts
          </StyledLink>
          <StyledLink>
            <PieChartIcon sx={{ marginRight: '8px' }} />
            Insights
          </StyledLink>
          <StyledLink>
            <SettingsIcon sx={{ marginRight: '8px' }} />
            Settings
          </StyledLink> */}
          
          <StyledLink>
            <LogoutIcon sx={{ marginRight: '8px' }} />
            Logout
          </StyledLink>
        </Box>
        <Box sx={{ marginLeft: '200px', flexGrow: 1, paddingLeft: '16px', paddingTop: '10px' }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;