import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';

function TextTab({ id }) {
  const [textData, setTextData] = useState([]);
  const [isTextDataFetched, setIsTextDataFetched] = useState(false);
  const [billData, setBillData] = useState([]);
  const [selectedTextId, setSelectedTextId] = useState('');
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    if (!isTextDataFetched) {
      fetch(`${process.env.REACT_APP_API_URL}/bill-text/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setTextData(data.text);
          setBillData(data.bill);
          setIsTextDataFetched(true);
          if (data.text.length > 0) {
            setSelectedTextId(data.text[0].id);
            setFileUrl(data.text[0].file_url);
          }
        })
        .catch(error => console.error('Error fetching text data:', error));
    }
  }, [id, isTextDataFetched]);

  const handleTextChange = (event) => {
    const selectedId = event.target.value;
    setSelectedTextId(selectedId);

    const selectedText = textData.find(text => text.id === selectedId);
    if (selectedText) {
      setFileUrl(selectedText.file_url);
    }
  };

  return (
  <Box>
    {billData && (
      <Box sx={{ marginBottom: 5 }}>
        {billData.description && billData.description.length > 0 && (
            <>
              <Typography variant="h6">Description</Typography>
              <Typography variant="body1">{billData.description}</Typography>
            </>
          )}
          {billData.policy_objective && billData.policy_objective.length > 0 && (
            <>
              <Typography variant="h6" sx={{ marginTop: 2 }}>InformNation Summary</Typography>
              <Typography variant="body1">{billData.policy_objective}</Typography>
            </>
          )}
      </Box>
    )}
    
    <Box>
      <FormControl fullWidth>
        <InputLabel id="text-select-label">Select Text</InputLabel>
        <Select
          sx={{ width: '30vw' }}
          labelId="text-select-label"
          value={selectedTextId}
          label="Select Text"
          onChange={handleTextChange}
        >
          {textData.map((text) => (
            <MenuItem key={text.id} value={text.id}>
              {text.status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {fileUrl && (
        <Box sx={{ marginTop: 2 }}>
          <iframe src={fileUrl} width="50%" height="600px" title="Bill Text" sx={{ border: '1px solid #E0E0E0' }} />
        </Box>
      )}
    </Box>
  </Box>
  );
}

export default TextTab;